'use client';

import Button from '@components/Button';
import Flag from '@components/Flag';
import type { NavigationLink } from '@components/header/types';
import { useMarket } from '@context/marketContext';
import { MobileMenuContext } from '@context/mobileMenuContext';
import { ArrowRightIcon } from '@ngg/icons';
import { useContext, useMemo } from 'react';

import { cn } from '@/lib/utils';

export default function MarketSelectorButton({
    className,
}: {
    className?: string;
}) {
    const { state } = useMarket();
    const { setNavigationTree, setNavigationDirection } =
        useContext(MobileMenuContext);

    const flag = state.market.code;

    const text = useMemo(() => state.market.label, [state.market.label]);

    return (
        <Button
            className={cn('[&>*:first-child]:hidden', className)}
            onClick={() => {
                setNavigationTree((prev) => [
                    ...prev,
                    {
                        type: 'Market',
                        slug: '',
                        title: '',
                    } satisfies NavigationLink,
                ]);
                setNavigationDirection('right');
            }}
            endIcon={<ArrowRightIcon className="ml-auto md:hidden" />}>
            <Flag
                code={flag}
                className={cn(
                    'mr-1 h-3 w-3 flex-none grow-0 rounded-full border border-black object-cover',
                )}
            />
            <span data-testid="market-selector-dropdown-country-name">
                {text}
            </span>
        </Button>
    );
}
