'use client';

import { useMarket } from '@context/marketContext';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';

import { FEATURE_FLAGS } from '@/config/featureFlags';
import {
    getLink,
    getMarketConfigFromCode,
    getMarketConfigsSortedByLabel,
} from '@/config/market-configurations';
import { cn } from '@/lib/utils';

import { MarketItem } from './MarketPopup';
import MarketSelectorOption from './MarketSelectorOption';

export default function MarketSelectorList() {
    const { state, onSave } = useMarket();

    const markets = getMarketConfigsSortedByLabel();

    const onClick = useCallback(
        (event: MouseEvent<HTMLLIElement>) => {
            event.preventDefault();
            const market = event.currentTarget.getAttribute('data-market');

            if (!market) {
                return;
            }

            const link = getLink(state.market, getMarketConfigFromCode(market));
            onSave({ link: link });
            return;
        },
        [onSave, state.market],
    );

    return (
        <ul className="flex w-full flex-col divide-y">
            {markets.map((market) => {
                if (FEATURE_FLAGS.marketSelector.new) {
                    return <MarketItem key={market.code} market={market} />;
                }
                return (
                    <MarketSelectorOption
                        key={market.code}
                        market={market}
                        data-market={market.code}
                        as="li"
                        role="button"
                        onClick={onClick}
                        className={cn(
                            'px-6 py-4',
                            market.code === state.market.code && 'bg-gray-100',
                        )}
                    />
                );
            })}
        </ul>
    );
}
