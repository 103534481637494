'use client';

import { useMutation } from '@apollo/client';
import { AUTH_COOKIE_NAME } from '@auth/helpers';
import type { AuthState } from '@context/authContext';
import type {
    AddAnonymousWishlistItemMutationVariables,
    AddWishlistItemMutationVariables,
    AnonymousWishlistAddItemResponse,
    DeleteWishlistItemMutationVariables,
    GenericResponseObject,
    WishlistAddTagToWishlistItemResponse,
    WishlistItemAddResponse,
    WishlistItemDto,
    WishlistItemWithTagsDto,
    WishlistTagResponse,
} from '@server/gql/graphql';
import {
    addAnonymousWishlistItemMutation,
    deleteAnonymousWishlistItemMutation,
} from '@server/queries/anonymousWishlist';
import {
    addWishlistItemMutation,
    addWishlistItemTagMutation,
    addWishlistTagMutation,
    createWishlistTagShareLinkMutation,
    deleteWishlistItemMutation,
    deleteWishlistItemTagMutation,
    deleteWishlistTagMutation,
    updateWishlistTagMutation,
} from '@server/queries/wishlist';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getCookie } from 'cookies-next';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    ANONYMOUS_WISHLIST_ID_NAME,
    ANONYMOUS_WISHLIST_TOKEN_NAME,
} from '@/utils/const';
import { getCorrectWishlist } from '@/utils/wishlist/getCorrectWishlist';

const getStatus = (
    isError: boolean,
    action: 'add' | 'delete',
): 'added' | 'removed' | 'error' => {
    if (action === 'delete') return isError ? 'error' : 'removed';
    return isError ? 'error' : 'added';
};

type ToggleWishlistTagParams = {
    tagId: number;
    wishlistItemIdOrProductId: string | number;
    variantId?: string;
    isInTag: boolean;
};

/**
 * @deprecated - will likely be replaced in NOGA-959: Revisit sitewide wishlist functionality and migrate into tanstack
 */
export type TWishlistContext = {
    isLoading: boolean;
    isFetching: boolean;
    isInWishlist: (
        productId: string,
        variantId?: string,
    ) => WishlistItemDto | WishlistItemWithTagsDto | undefined;
    toggleWishlist: (
        productId: string,
        variantId?: string,
    ) => Promise<
        | {
              status: 'added' | 'removed' | 'error';
              response:
                  | AnonymousWishlistAddItemResponse
                  | GenericResponseObject
                  | WishlistItemAddResponse;
          }
        | undefined
    >;
    addToWishlist: (
        productId: string,
        variantId?: string,
    ) => Promise<
        AnonymousWishlistAddItemResponse | WishlistItemAddResponse | undefined
    >;
    removeFromWishlist: (
        wishlistItemId?: number | null,
    ) => Promise<GenericResponseObject | undefined>;
    toggleWishlistTag: (
        params: ToggleWishlistTagParams,
    ) => Promise<
        | WishlistAddTagToWishlistItemResponse
        | GenericResponseObject
        | null
        | undefined
    >;
    addWishlistTag: (
        name: string,
    ) => Promise<WishlistTagResponse | null | undefined>;
    deleteWishlistTag: (
        tagId: number,
        deleteUnderlyingItems: boolean,
    ) => Promise<GenericResponseObject | null | undefined>;
    updateWishlistTag: (
        tagId: number,
        newName: string,
    ) => Promise<GenericResponseObject | null | undefined>;
    createWishlistTagShareLink: (
        tagId: number,
    ) => Promise<WishlistTagResponse | null | undefined>;
    wishlistCookies?: {
        id?: number;
        token?: string;
    };
    setWishlistData: Dispatch<
        SetStateAction<(WishlistItemDto | WishlistItemWithTagsDto)[]>
    >;
    wishlistData: (WishlistItemDto | WishlistItemWithTagsDto)[];
};

/**
 * @deprecated - will likely be replaced in NOGA-959: Revisit sitewide wishlist functionality and migrate into tanstack
 */
export const WishlistContext = createContext<TWishlistContext | null>(null);

/**
 * @deprecated - will likely be replaced in NOGA-959: Revisit sitewide wishlist functionality and migrate into tanstack
 */
export const WishlistProvider = ({ children }: PropsWithChildren<any>) => {
    const { data } = useQuery<AuthState>({ queryKey: ['auth'] });
    const queryClient = useQueryClient();
    const [wishlistData, setWishlistData] = useState<
        (WishlistItemDto | WishlistItemWithTagsDto)[]
    >([]);
    const [wishlistCookies, setWishlistCookies] = useState(() => {
        const id = parseInt(
            getCookie(ANONYMOUS_WISHLIST_ID_NAME)?.toString() ?? '',
            10,
        );
        const token = getCookie(ANONYMOUS_WISHLIST_TOKEN_NAME)?.toString();
        return {
            id,
            token,
        };
    });
    const [isFetching, setFetching] = useState(true);

    const [addWishlistItemMutate, { loading: loadingAddWishlistItem }] =
        useMutation(addWishlistItemMutation);
    const [deleteWishlistItem, { loading: loadingDeleteWishlistItem }] =
        useMutation(deleteWishlistItemMutation);
    const [
        addAnonymousWishlistItemMutate,
        { loading: loadingAddAnonymousWishlistItem },
    ] = useMutation(addAnonymousWishlistItemMutation);
    const [
        deleteAnonymousWishlistItem,
        { loading: loadingDeleteAnonymousWishlistItem },
    ] = useMutation(deleteAnonymousWishlistItemMutation);
    const [addWishlistItemTag, { loading: loadingAddWishlistItemTag }] =
        useMutation(addWishlistItemTagMutation);
    const [deleteWishlistItemTag, { loading: loadingDeleteWishlistItemTag }] =
        useMutation(deleteWishlistItemTagMutation);
    const [mutateAddWishlistTag, { loading: loadingAddWishlistTag }] =
        useMutation(addWishlistTagMutation);
    const [mutateDeleteWishlistTag, { loading: loadingDeleteWishlistTag }] =
        useMutation(deleteWishlistTagMutation);
    const [mutateUpdateWishlistTag, { loading: loadingUpdateWishlistTag }] =
        useMutation(updateWishlistTagMutation);
    const [
        mutateCreateWishlistTagShareLink,
        { loading: loadingCreateWishlistTagShareLink },
    ] = useMutation(createWishlistTagShareLinkMutation);
    const [loading, setLoading] = useState(false);
    const isLoading =
        loading ||
        loadingAddWishlistItem ||
        loadingDeleteWishlistItem ||
        loadingAddAnonymousWishlistItem ||
        loadingDeleteAnonymousWishlistItem ||
        loadingAddWishlistItemTag ||
        loadingDeleteWishlistItemTag ||
        loadingAddWishlistTag ||
        loadingDeleteWishlistTag ||
        loadingUpdateWishlistTag ||
        loadingCreateWishlistTagShareLink;

    const isInWishlist = useCallback(
        (productId: string, variantId = '') => {
            if (!productId) return undefined;
            if (!wishlistData.length) return undefined;

            return wishlistData?.find(
                (item) =>
                    item.productId === productId &&
                    (item.variantId ?? '') === variantId,
            );
        },
        [wishlistData],
    );

    const addAnonymousItem = useCallback(
        async (variables: AddAnonymousWishlistItemMutationVariables) => {
            const response = await addAnonymousWishlistItemMutate({
                variables: {
                    wishlistToken: wishlistCookies?.token,
                    payload: {
                        wishlistId: wishlistCookies?.id,
                        ...variables?.payload,
                    },
                },
            });
            const {
                data,
                isError = true,
                error,
            } = response?.data?.addAnonymousWishlistItem || {};

            const { ...rest } = data || {};

            if (rest) {
                rest.wishlistId = rest.wishlistId || undefined;
                setWishlistData((old) => {
                    return [...old, rest] as WishlistItemDto[];
                });
            }

            if (!isError && !wishlistCookies?.token && !wishlistCookies?.id) {
                const { wishlistId, token } = data || {};
                if (wishlistId && token) {
                    setWishlistCookies({
                        id: wishlistId,
                        token,
                    });
                    await fetch('/api/wishlist', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            wishlistId,
                            wishlistToken: token,
                        }),
                    });
                }
            }
            await queryClient.invalidateQueries({
                queryKey: ['wishlist'],
                type: 'all',
            });
            return {
                data,
                isError,
                error,
            };
        },
        [queryClient, addAnonymousWishlistItemMutate, wishlistCookies],
    );

    const deleteAnonymousItem = useCallback(
        async (wishlistItemId: number) => {
            if (!wishlistCookies?.id || !wishlistCookies?.token) {
                return {
                    data: null,
                    isError: true,
                    error: {
                        code: 200,
                        message: 'No wishlist cookies found',
                        success: false,
                    },
                };
            }

            const response = await deleteAnonymousWishlistItem({
                variables: {
                    wishlistId: wishlistCookies?.id,
                    wishlistItemId,
                    wishlistToken: wishlistCookies?.token,
                },
            });
            const {
                data,
                isError = true,
                error,
            } = response?.data?.deleteAnonymousWishlistItem || {};

            if (data) {
                setWishlistData((old) => {
                    return old.filter(
                        (item) => item.id !== wishlistItemId,
                    ) as WishlistItemDto[];
                });
            }
            await queryClient.invalidateQueries({
                queryKey: ['wishlist'],
                type: 'all',
            });
            return {
                data,
                isError,
                error,
            };
        },
        [queryClient, deleteAnonymousWishlistItem, wishlistCookies],
    );

    const addItem = useCallback(
        async (variables: AddWishlistItemMutationVariables) => {
            const response = await addWishlistItemMutate({
                variables,
            });
            const {
                data,
                isError = true,
                error,
            } = response?.data?.addWishlistItem || {};

            const { ...rest } = data || {};

            if (rest) {
                setWishlistData((old) => [...old, rest]);
            }
            await queryClient.invalidateQueries({
                queryKey: ['wishlist'],
                type: 'all',
            });
            return {
                data,
                isError,
                error,
            };
        },
        [queryClient, addWishlistItemMutate],
    );

    const deleteItem = useCallback(
        async (variables: DeleteWishlistItemMutationVariables) => {
            const response = await deleteWishlistItem({
                variables,
            });
            const {
                data,
                isError = true,
                error,
            } = response?.data?.deleteWishlistItem || {};

            if (data) {
                setWishlistData((old) => {
                    return old.filter(
                        (item) => item.id !== variables.wishlistItemId,
                    ) as [];
                });
            }
            await queryClient.invalidateQueries({
                queryKey: ['wishlist'],
                type: 'all',
            });
            return {
                data,
                isError,
                error,
            };
        },
        [queryClient, deleteWishlistItem],
    );

    const addToWishlist = useCallback(
        async (productId: string, variantId = '') => {
            if (!productId) return undefined;
            setLoading(true);

            if (data?.user) {
                const response = await addItem({
                    token: await data?.user.getIdToken(),
                    payload: {
                        productId,
                        variantId,
                    },
                });
                setLoading(false);

                return response;
            }

            const response = await addAnonymousItem({
                wishlistToken: wishlistCookies?.token,
                payload: {
                    productId,
                    variantId,
                    wishlistId: wishlistCookies?.id,
                },
            });

            setLoading(false);
            await queryClient.invalidateQueries({
                queryKey: ['wishlist'],
                type: 'all',
            });
            return response;
        },
        [
            queryClient,
            addAnonymousItem,
            addItem,
            data?.user,
            wishlistCookies?.token,
            wishlistCookies?.id,
        ],
    );

    const removeFromWishlist = useCallback(
        async (wishlistItemId?: number | null) => {
            if (wishlistItemId == null) return undefined;
            setLoading(true);

            let response;

            if (data?.user) {
                response = await deleteItem({
                    token: await data?.user.getIdToken(),
                    wishlistItemId,
                });
            } else {
                response = await deleteAnonymousItem(wishlistItemId);
            }

            await queryClient.invalidateQueries({
                queryKey: ['wishlist'],
                type: 'all',
            });

            setLoading(false);

            return response;
        },
        [queryClient, deleteItem, deleteAnonymousItem, data?.user],
    );

    const toggleWishlist = useCallback(
        async (productId: string, variantId = '') => {
            if (!productId) return undefined;

            setLoading(true);

            const inWishlist = isInWishlist(productId, variantId);

            if (data?.user) {
                if (inWishlist) {
                    const response = await deleteItem({
                        token: await data?.user.getIdToken(),
                        wishlistItemId: inWishlist.id as number,
                    });
                    setLoading(false);

                    return {
                        status: getStatus(response?.isError, 'delete'),
                        response,
                    };
                }

                const response = await addItem({
                    token: await data?.user.getIdToken(),
                    payload: {
                        productId,
                        variantId,
                    },
                });
                setLoading(false);

                return {
                    status: getStatus(response?.isError, 'add'),
                    response,
                };
            }

            if (inWishlist?.__typename === 'WishlistItemDto' && inWishlist) {
                const response = await deleteAnonymousItem(
                    inWishlist.id as number,
                );
                setLoading(false);

                return {
                    status: getStatus(response?.isError, 'delete'),
                    response,
                };
            }

            const response = await addAnonymousItem({
                wishlistToken: wishlistCookies?.token,
                payload: {
                    productId,
                    variantId,
                    wishlistId: wishlistCookies?.id,
                },
            });

            setLoading(false);
            await queryClient.invalidateQueries({
                queryKey: ['wishlist'],
                type: 'all',
            });
            return {
                status: getStatus(response?.isError, 'add'),
                response,
            };
        },
        [
            queryClient,
            data?.user,
            addItem,
            deleteItem,
            addAnonymousItem,
            deleteAnonymousItem,
            isInWishlist,
            wishlistCookies,
        ],
    );

    const toggleWishlistTag = useCallback(
        async (params: ToggleWishlistTagParams) => {
            const { tagId, wishlistItemIdOrProductId, variantId, isInTag } =
                params;

            if (!data?.user) return null;

            if (isInTag) {
                const response = await deleteWishlistItemTag({
                    variables: {
                        token: await data?.user.getIdToken(),
                        tagId,
                        wishlistItemId: wishlistItemIdOrProductId as number,
                    },
                });

                return response.data?.deleteWishlistItemTag;
            }

            const response = await addWishlistItemTag({
                variables: {
                    token: await data?.user.getIdToken(),
                    payload: {
                        tagId,
                        productId: (wishlistItemIdOrProductId as string) || '',
                        variantId: variantId || '',
                    },
                },
            });

            return response.data?.addWishlistItemTag;
        },
        [data?.user, addWishlistItemTag, deleteWishlistItemTag],
    );

    const addWishlistTag = useCallback(
        async (name: string) => {
            if (!data?.user) return null;

            const response = await mutateAddWishlistTag({
                variables: {
                    token: await data?.user.getIdToken(),
                    payload: {
                        name,
                    },
                },
            });

            return response.data?.addWishlistTag;
        },
        [data?.user, mutateAddWishlistTag],
    );

    const deleteWishlistTag = useCallback(
        async (tagId: number, deleteUnderlyingItems: boolean) => {
            if (!data?.user) return null;

            const response = await mutateDeleteWishlistTag({
                variables: {
                    token: await data?.user.getIdToken(),
                    tagId,
                    deleteUnderlyingItems,
                },
            });

            return response.data?.deleteWishlistTag;
        },
        [data?.user, mutateDeleteWishlistTag],
    );

    const updateWishlistTag = useCallback(
        async (tagId: number, newName: string) => {
            if (!data?.user) return null;

            const response = await mutateUpdateWishlistTag({
                variables: {
                    token: await data?.user.getIdToken(),
                    payload: {
                        id: tagId,
                        name: newName,
                    },
                },
            });

            return response.data?.updateWishlistTag;
        },
        [data?.user, mutateUpdateWishlistTag],
    );

    const createWishlistTagShareLink = useCallback(
        async (tagId: number) => {
            if (!data?.user) return null;

            const response = await mutateCreateWishlistTagShareLink({
                variables: {
                    token: await data?.user.getIdToken(),
                    tagId,
                },
            });

            return response.data?.createWishlistTagShareLink;
        },
        [data?.user, mutateCreateWishlistTagShareLink],
    );

    useEffect(() => {
        const authToken = getCookie(AUTH_COOKIE_NAME)?.toString();
        const anonymousWishlistId = parseInt(
            getCookie(ANONYMOUS_WISHLIST_ID_NAME)?.toString() ?? '',
            10,
        );
        const anonymousWishlistToken = getCookie(
            ANONYMOUS_WISHLIST_TOKEN_NAME,
        )?.toString();

        setFetching(true);

        getCorrectWishlist({
            authToken,
            anonymousWishlistId,
            anonymousWishlistToken,
            pageSize: 150,
        })
            .then((response) => {
                setWishlistCookies({
                    id: anonymousWishlistId,
                    token: anonymousWishlistToken,
                });
                setWishlistData(response?.data?.wishlistItems ?? []);
            })
            .catch(() => console.warn('unable to find wishlist'))
            .finally(() => setFetching(false));
    }, []);

    const state = useMemo(
        () => ({
            toggleWishlist,
            addToWishlist,
            removeFromWishlist,
            isInWishlist,
            toggleWishlistTag,
            addWishlistTag,
            deleteWishlistTag,
            updateWishlistTag,
            createWishlistTagShareLink,
            wishlistCookies,
            isLoading,
            isFetching,
            setWishlistData,
            wishlistData,
        }),
        [
            toggleWishlist,
            addToWishlist,
            removeFromWishlist,
            isInWishlist,
            toggleWishlistTag,
            addWishlistTag,
            deleteWishlistTag,
            updateWishlistTag,
            createWishlistTagShareLink,
            wishlistCookies,
            isLoading,
            isFetching,
            setWishlistData,
            wishlistData,
        ],
    );

    return (
        <WishlistContext.Provider value={state}>
            {children}
        </WishlistContext.Provider>
    );
};

/**
 * @deprecated - will likely be replaced in NOGA-959: Revisit sitewide wishlist functionality and migrate into tanstack
 */
export const useWishlist = () => {
    const context = useContext(WishlistContext);
    if (context === undefined)
        throw new Error('useWishlist must be used within a WishlistProvider');
    return context as TWishlistContext;
};
