import fetchCuratedProducts from '@server/requests/product/fetchCuratedProducts';
import type { QueryClient } from '@tanstack/react-query';
import { deleteCookie, setCookie } from 'cookies-next';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import type { MouseEvent } from 'react';

import {
    getLink,
    getMarketConfigFromCode,
} from '@/config/market-configurations';
import type { MarketConfiguration } from '@/config/markets/const';

export async function interceptRoute(
    event: MouseEvent<HTMLAnchorElement>,
    router: AppRouterInstance,
    currentMarket: MarketConfiguration,
    qc: QueryClient,
) {
    event.preventDefault();
    const lang = event.currentTarget.getAttribute('data-lang');
    const market = event.currentTarget.getAttribute('data-market');
    const targetMarket = getMarketConfigFromCode(market ?? currentMarket.code);
    if (targetMarket.code === currentMarket.code) {
        setCookie('ng_lang', targetMarket.language.code);
        setCookie('ng_market', targetMarket.code);
        deleteCookie('ng_suggestion');
        return qc.setQueryData(['market'], { open: false });
    }
    const session = window.sessionStorage.getItem('siblings');
    let pathname = '';
    if (session) {
        if (session.includes('product')) {
            const [, id] = session.split(':');
            const [product] = await fetchCuratedProducts([id], 1, targetMarket);
            if (product.ProductURL) pathname += product.ProductURL;
        } else {
            const links = JSON.parse(session);
            if (lang && links[lang]) pathname += links[lang];
        }
    }
    const origin = getLink(currentMarket, targetMarket);
    setCookie('ng_lang', lang);
    setCookie('ng_market', targetMarket.code);
    deleteCookie('ng_suggestion');
    return router.push(origin + pathname);
}
